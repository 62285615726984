import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Links from "../../components/services/links/links"

export default function PersonalInjury({ data }) {
  return (
    <Layout>
      <SEO title="Wills in Islam" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="Services"
      />
      <div className="width75">
        <h1 className="title">Wills in Islam</h1>
        <Links type="about" />
        <div className="ribbon-top">
          <p>
            Islam has placed great emphasis on distributing the wealth of a
            Muslim in a pre-determined manner. The principles for such purpose
            have endured for over fourteen centuries and continue to play a
            vital role today. Islam, on one hand, allows Muslims to fulfil their
            religious obligations, and on the other, preventing wealth from
            being overly concentrated in the hands of the few. In doing so,
            Islam ensuring womenfolk have guaranteed financial rights to their
            own.
          </p>
          <p>
            Remember! The courts enforce a legally valid WILL only. Preparing
            Sharı‘ah Will is not recognised under English Law. The only way to
            enforce Sharı‘ah WILL is to place the assets/wealth as a TRUST.
          </p>
          <p>
            Keep in mind that in Sharı‘ah, a Muslim with wealth is duty-bound to
            write a will. Our team therefore, can help to guide you through the
            tax legislation and work out the best way forward.
          </p>
          <Img
            fluid={data.bottomImage.childImageSharp.fluid}
            style={{ height: "150px" }}
            imgStyle={{ objectPosition: "center 40%" }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "tower-bridge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomImage: file(relativePath: { eq: "will.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
